import React, { Component } from "react"
import { graphql, Link } from "gatsby"
import { Helmet } from "react-helmet"

import DefaultBlogImage from "../../../assets/images/default-blog-image.png"
import SEO from "../../components/seo"
import Layout from "../../modules/layout"
import Breadcrumbs from "../../modules/breadcrumbs"
import SimpleHero from "../../modules/simpleHero"
import Grid from "../../modules/grid"
import Categories from "../../components/categories"
import Pagination from "../../components/pagination"

class BlogPage extends Component {
  render() {
    const { data, location } = this.props
    const { currentPage, blogPages } = this.props.pageContext
    const title = `The PeopleFluent Blog | Expert Insights for Enterprise HR and L&D`
    const description = null
    const image = null
    const canonical = `${data.sanitySiteConfig.url}/blog/`
    return (
      <Layout>
        <Helmet>
          <link rel="canonical" href={canonical} />
        </Helmet>
        <SEO title={title} description={description} image={image} />
        <Breadcrumbs location={location}>
          <Link to="/">Home</Link>
          <p>Blog</p>
        </Breadcrumbs>
        <SimpleHero heading="The PeopleFluent Blog" />
        <section className="blocksMenuGrid">
          <Categories
            postType="blog"
            activeCat="blog"
            first="true"
            usedCats={data?.allBlogCats}
          />
          {data.allSanityBlog.edges.length && (
            <Grid
              blocks={data.allSanityBlog.edges}
              defaultImage={DefaultBlogImage}
            />
          )}
          {blogPages > 1 && (
            <Pagination
              type="blog"
              currentPage={currentPage}
              totalPages={blogPages}
            />
          )}
        </section>
      </Layout>
    )
  }
}

export default BlogPage

export const query = graphql`
  query($skip: Int, $limit: Int) {
    sanitySiteConfig {
      url
    }
    allBlogCats: allSanityBlog {
      distinct(field: category___slug___current)
    }
    allSanityBlog(
      sort: { fields: publishedAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          _type
          title
          slug {
            current
          }
          author {
            name
          }
          excerpt
          category {
            title
            slug {
              current
            }
          }
          image {
            asset {
              fluid {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
    }
  }
`
