import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"

import styles from "./categories.module.scss"

const Categories = props => (
  <StaticQuery
    query={graphql`
      {
        allSanityCategory {
          edges {
            node {
              slug {
                current
              }
              title
              _id
            }
          }
        }
      }
    `}
    render={data => {
      const cats = data.allSanityCategory.edges
      const { usedCats } = props
      const filteredCats = cats?.filter(({ node: cat }) => {
        if (usedCats?.distinct?.includes(cat.slug.current)) {
          return cat
        }
      })

      return (
        <div
          className={`${styles.categories} ${
            props.first ? styles.categoriesTop : ""
          }`}
        >
          <ul className={styles.categoriesList}>
            <li
              className={
                props.activeCat === props.postType ? styles.isActive : ""
              }
            >
              <Link to={`/${props.postType}/`}>All</Link>
            </li>
            {filteredCats.map(({ node: cat }) => (
              <li
                key={cat._id}
                className={
                  props.activeCat === cat.slug.current ? styles.isActive : ""
                }
              >
                <Link to={`/${props.postType}/${cat.slug.current}/`}>
                  {cat.title}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )
    }}
  />
)

export default Categories
